.border-color-black-transparent {
	border-color: rgba(255, 255, 255, 0.1) !important;
}

.shadow-text {
	text-shadow: 3px 0px 7px rgba(255, 255, 255, 0.25), -3px 0px 7px rgba(255, 255, 255, 0.25),
		0px 4px 7px rgba(255, 255, 255, 0.25);
}

.bg-transparent-gradient-fast-blue-purple {
	background-image: linear-gradient(
		to right top,
		rgb(0, 184, 184),
		rgb(0, 184, 184),
		rgba(0, 184, 184, 0.43),
		rgba(0, 184, 184, 0.41),
		rgba(0, 184, 184, 0.51)
	);
}

.navbar.navbar-light .navbar-nav .nav-link {
	color: #fff;
}


.footer-sticky{
	z-index: -4 !important;
}

.footer-digital-agency.footer-light a:hover {
	color: #ffb81c;
}

.bg-dark-1 {
	background-color: black;
}




.form-results .bg-success {
	padding: 13px 20px;
	color: #fff;
}

.form-results .bg-danger {
	padding: 13px 20px;
	color: #fff;
}

.form-results {
	padding: 0 !important;
}

.bg-gradient-sky-blue-pink {
	background-image: linear-gradient(to right top,#ffb81c,#00b8b8b4,#33cfcf6b,#33cfcf6b,#33cfcf6b);
}


.medium-input, .medium-select select, .medium-textarea {
	padding: 15px 20px;
	font-size: 14px;
	border-radius: 10px;
}


.btn.btn-fancy.btn-large {
	font-size: 13px;
	letter-spacing: 1px;
	padding: 13px 32px;
	border-radius: 10px;
}
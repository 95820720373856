body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn text-dark-link.text-yellow-ochre-light,
.text-yellow-ochre-light,
a.text-yellow-ochre-light-hover:hover {
  color: #4e71c3;
}

.bg-penguin-white {
  background-color: #eaeef1;
}

.bg-yellow-ochre-light {
  background-color: #4e6bc3;
}

.footer-decor.footer-dark a:hover {
  color: #3d82cb;
}

.btn text-dark-link.text-dark-golden-yellow,
.text-dark-golden-yellow,
a.text-dark-golden-yellow-hover:hover {
  color: #3d7bcb;
}

.bg-parrot-green {
  background-color: #0278e060;
}

.theme-demos .all-demo,
.theme-demos .buy-theme {
  position: absolute;
  left: -80px;
  transform: translateY(-50%) translateY(-7px);
  cursor: pointer;
  z-index: 10;
  transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0.45s;
  min-width: 80px;
}

.theme-demos .buy-theme{
  background: #37514B;
}

.bg-gradient-fast-blue-purple {
	background-image: linear-gradient(to right top,#023838,#066262,#29848482,#066060,#022828);
}

.all-demo.email {
  bottom: 265px;
}

.theme-wrapper p {
  font-size: 9px !important;
  margin: 0;
  text-align: center !important;
}
.theme-demos .all-demo,
.theme-demos .buy-theme {
  position: absolute;
  left: -80px;
  transform: translateY(-50%) translateY(-7px);
  cursor: pointer;
  z-index: 10;
  transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0.45s;
  min-width: 80px;
}

.theme-demos .all-demo .theme-wrapper i,
.theme-demos .buy-theme .theme-wrapper i {
  margin-right: 0;
  font-size: 21px;
}

.theme-demos .all-demo .theme-wrapper,
.theme-demos .buy-theme .theme-wrapper {
  padding: 0;
  position: relative;
  top: 0;
  opacity: 1;
  transition-delay: 0.3s;
}

.all-demo.call {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.theme-demos .all-demo a,
.theme-demos .buy-theme a {
  color: #232323;
  font-size: 11px;
  padding: 12px 15px;
  display: block;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
}

.padding-7-half-rem-lr {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  background: linear-gradient(45deg, #053e38, #2d736c2e, #88f4e938)
}

.bg-black {
  background-color: #39cabb;
}

.default-logo{
  width: 120px !important;
  height: 80px;
  max-height: inherit !important;
}

.room .blog-post-image img,
.room .portfolio-image img {
  width: 100%;
  height: 352px;
}

.btn text-dark-link.text-tussock,
.text-tussock,
a.text-tussock-hover:hover {
  color: #4a5ad9;
}

.btn text-dark-gradient-tan-geraldine {
  background-image: linear-gradient(to right, #8195fe, #4a5ad9, #819afe);
  color: #fff;
  background-size: 200% auto;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn text-dark-link.text-salmon-rose,
.text-salmon-rose,
a.text-salmon-rose-hover:hover {
  color: #5072c3;
}

.swiper-container {
  width: 100%;
  /* height: 100%; */
  min-width: 100%;
}

#features .margin-25px-bottom {
  margin-bottom: 25px;
  width: 88px;
  height: 85px;
  border-radius: 6px;
}

.feature-item.h-100.w-100 {
  /* background-color: #0000006b; */
  width: calc(100% - 10px) !important;
  height: calc(100% - 10px) !important;
  margin: 5px;
  /* border: 1px solid #e3e3e3; */
}

.feature-item-holder:hover .feature-item.h-100.w-100 {
  border: none;
}

.feature-item-holder {
  padding: 0 !important;
}

.rooms .blog-post-image img,
.rooms .portfolio-image img {
  width: 100%;
  height: 275px;
}

.room .architecture-overlap {
  background: #000000c7;
  margin: 1rem;
  border-radius: 0px;
}

.room .tab-style-07 .nav-tabs > li.nav-item > a.nav-link {
  color: #fff;
  border-bottom: none;
  padding: 10px;
  font-size: 13px;
}

.room .tab-style-07 .nav-tabs > li.nav-item > a.nav-link.active {
  color: #fff;
  border-bottom: 3px solid #393c42;
  font-weight: bold;
}

.btn text-dark-link.text-parrot-green,
.text-parrot-green,
a.text-parrot-green-hover:hover {
  color: #007bff;
}

.empty-content {
  width: 100%;
  height: 200px;
  text-align: center;
  padding-top: 100px;
}

.room-read-content {
  line-height: 1.5;
  font-size: 14px;
  text-align: justify;
}

.room .white-move .swiper-slide,
.room .white-move .swiper-wrapper {
  height: 500px;
}

.py-md-0.overflow-visible.position-relative {
  z-index: 2;
}

@media (max-width: 991px) {

  .room {
    /* background-color: #23262d; */
  }
  .room .architecture-overlap {
    background: none;
    margin: 0rem;
    border-radius: 0px;
  }
}

@media (min-width: 990px) {
  .room
    .swiper-pagination.swiper-light-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    margin-top: -58px;
    text-align: left;
    left: 3rem !important;
  }

  .room .alt-font.font-weight-300.text-dark.margin-4-rem-bottom {
    text-align: left;
    margin-left: 2rem;
    margin-bottom: 12rem;
  }

  .book-room-btn text-dark {
    position: absolute;
    left: 7rem;
  }
}

.slider-navigation-style-07.swiper-button-next,
.slider-navigation-style-07.swiper-button-prev {
  background-color: rgba(23, 23, 23, 0.5);
  color: #fff;
  z-index: 900000;
  pointer-events: all;
  cursor: pointer;
}
.slider-navigation-style-05.swiper-button-next,
.slider-navigation-style-05.swiper-button-prev {
  z-index: 900000;
  pointer-events: all;
  cursor: pointer;
}

.booking-form-open {
  overflow: hidden !important;
}

.booking-form-open .mfp-container.mfp-inline-holder {
  background: #fff;
  /* overflow-y: scroll; */
  top: 200px;
}

.booking-form-open .mfp-content {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
  left: 0;
  right: 0;
  bottom: 0;
  top: 5rem !important;
}

.notify-form-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.newsletter-email input {
  padding-right: 130px;
  background: #f5f5f50a;
  border-width: 2px !important;
  height: 50px;
}
.newsletter-email .required.error {
  border: 2px solid red !important;
}

.animate__fadeIn.show-out {
  display: none !important;
}

.animate__fadeIn.show-in {
  display: block !important;
}
/* 
.pointer-events {
  pointer-events: all !important;
  cursor: pointer !important;
  position: relative !important;
  background: red !important;
  z-index: 9000000000;
} */

.tp-splitted.tp-charsplit {
  color: #007bff;
}

.home-decor .tp-bullet {
  background: #4c6ebf !important;
  opacity: 0.6;
  border: 1px solid #4c6ebf !important;
  width: 10px !important;
  height: 10px !important;
}

.btn text-dark.btn text-dark-fast-yellow {
  background: #007bff;
  border-color: #007bff;
  color: #232323;
}

.btn text-dark.btn text-dark-fast-yellow:focus,
.btn text-dark.btn text-dark-fast-yellow:hover {
  background: 0 0;
  color: #007bff;
  font-weight: bold;
}

.bg-extra-medium-slate-blue {
	background-color: #00152b !important;
}

.btn text-dark.btn text-dark-box-shadow.btn text-dark-white:hover, .btn text-dark.btn text-dark-white:focus {
	background: #fff0;
	color: #fff;
}

.option-price-label {
	font-size: 10px;
	text-transform: capitalize;
	width: 100%;
}

.option-price-value {
	margin-bottom: -10px !important;
}

.page.option-price-label {
	font-size: 13px;
	margin-top: -10px;
  /* font-weight: bold; */


}
.page.option-price-label .inner-label {
	font-size: 12px;
	margin-top: -13px !important;
	font-weight: initial;
  font-style: italic;
}


.portfolio-box .portfolio-image {
	position: relative;
	overflow: hidden;
	border-radius: 8px;
}

.grid.gutter-extra-large li {
	padding: 10px;
}
.buy-theme.sm-display-none.whatsapp {
	border-bottom-left-radius: 8px;
}

.all-demo.email {
	border-top-left-radius: 8px;
}

.nav-item.active a {
	font-weight: bold !important;
}



.align-center{

    display: inline-flex !important;
    align-items: center;

}

header .navbar .navbar-brand img, header .sidebar-nav-menu .navbar-brand img, header.side-menu .logo img {
	max-height: 55px;
}

.ares.tparrows {
	cursor: pointer;
	background: transparent;
	min-width: 60px;
	min-height: 60px;
	position: absolute;
	display: block;
	z-index: 100;
	border-radius: 50%;
	border: 2px solid #fff;
	color: #fff !important;
}

.ares.tparrows::before {
	font-family: "revicons";
	font-size: 25px;
	color: #fff;
	display: block;
	line-height: 60px;
	text-align: center;
	-webkit-transition: color 0.3s;
	-moz-transition: color 0.3s;
	transition: color 0.3s;
	z-index: 2;
	position: relative;
}

.home-startup .ares:hover {
	background: transparent;
	border-color: #fff;
}


.bg-gradient-dark-slate-blue-transparent.top-bottom {
	background: rgba(31,35,44,0);
	background: -moz-linear-gradient(0deg,rgba(0,0,0,0) 0,#1f232c 100%);
	background: -webkit-linear-gradient(0deg,rgba(0,0,0,0) 0,#1f232c 100%);
	background: -o-linear-gradient(0deg,rgba(0,0,0,0) 0,#1f232c 100%);
	background: -ms-linear-gradient(0deg,rgba(0,0,0,0) 0,#1f232c 100%);
	background: linear-gradient(0deg,rgba(0,0,0,0) 0,#1f232c 100%);
}


.header-light.navbar.navbar-light .navbar-nav .nav-link {
	/* color: #000; */
}

.blog-post .blog-post-image, .swiper-container {
	border-radius: 0 !important;
}

.paper .c3 {
	vertical-align: baseline;
	font-size: 12pt;
	/* font-family: "SimSun"; */
	font-weight: 700;

}

.paper .c4 {
	line-height: 1.0;
	text-align: left;
  text-align: justify;
  line-height: 1.5;
}

.team-details {
	font-size: 13px;
	line-height: 1.5;
	text-align: justify;
}

.view-scroll{
  overflow-y: scroll !important;
}

.team-img {
  width: 360px;
  height: 480px;
}

.landing img {
  width: 250px;
}

.home-magazine.blog-metro .post-details .blog-category {
	font-size: 12px;
	line-height: 20px;
	font-weight: 500;
	text-transform: uppercase;
	color: #fff;
	padding: 6px 13px;
	background: #37514B;
	border-radius: 2px;
}

.btn text-dark-link.text-yellow-ochre, .text-yellow-ochre, a.text-yellow-ochre-hover:hover {
	color: #37514B;
}

.blog-post .blog-post-image {
	border-radius: 10px;
}

.swiper-container {
	border-radius: 10px;
}

@media (min-width: 991px) {
  
  .h-80{
    height: 80px;
  }

}


.navbar.navbar-expand-lg.navbar-light.bg-transparent.header-light.fixed-top.navbar-boxed.header-reverse-scroll.border-bottom.border-color-black-transparent {
	background: rgba(0,0,0,0.1);
	box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
}


.bg-orange-zest {
	background-color: #37514B;
}

.blog-section-summary-item .blog-post .blog-post-image {
	height: 250px !important;
}
.blog-section-summary-item {
	margin-bottom: 2rem ;
}

.blog-latest .swiper-container {
    border-radius: 10px;
    max-height: 529px !important;
}

.blog-list-item-d img {
	max-height: 235px;
	height: 235px;
}



.text-transition_inner div {
	text-align: center !important;
	width: 100% !important; 
}



.blog .row.align-items-center p {
	width: 100%;
}


.client-logo-style-07 .client-logo {
	margin-top: 1rem;
}


.navbar.top-space.navbar-expand-lg.navbar-light.bg-white.header-light.fixed-top.header-reverse-scroll.navbar-boxed .nav-link {
	color: #232323;
}

.slide-partner{
  /* margin: auto 12px !important; */
  /* width: 220px !important; */
  /* height: 167px !important;  */
}
.slide-partner-img{
  width: 220px !important;
  height: 167px !important;
}
.slide-team-img{
  width: 240px;
  height: 360px;
}
.team-img {
  width: 360px;
  height: 480px;
}

.faq-table-cell{
  width: 24.2%;
  padding: 10px;
}

.call-center-table-cell{
  width: 18.2%;
  padding: 10px;
  display: flex;
  justify-content: end;
  flex-direction: column;  
}

@media (max-width: 767px){
  .call-center-table-cell {
    width: 100%;
  }
}

.previous-iris-text{
  color: #00b8b8; 
}

::-webkit-input-placeholder{
  color: black;
}

INPUT::-webkit-input-placeholder, 
INPUT:-moz-placeholder {
    color:red;
}
input[placeholder], [placeholder], *[placeholder]
{
    color:green !important;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.form-row>.col, .form-row>[class*=col-] {
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.theme-demos{
  display: block !important;
}

.tawk-custom-color{
  background-color: #37514B;
}

.border.faq-table-cell img {
  width: 210px;
  height: 190px;
}

.call-center-table-cell img {
  width: 300px;
  height: 120px;
}

@media(max-width:500px){
  .head{
    right: 200px;
  }
}
div > input, div > select, div > textarea {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.btn-primary {
  color: #fff;
  background-color: #37514B;
  border-color: #37514B;
}